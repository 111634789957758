import React, { useState, useEffect } from 'react';
import { DndContext } from '@dnd-kit/core';
import { Droppable } from '../components/DND/Droppable.js';
import { Draggable } from '../components/DND/Draggable.js';
import "../style.css";

//https://codesandbox.io/examples/package/@dnd-kit/core could be helpful 

const bands = [
  {
    id: 1,
    name: 'Nightwish',
    albums: 9,
    members: 6,
    formed_in: 1996,
  },
  {
    id: 2,
    name: 'Metallica',
    albums: 10,
    members: 4,
    formed_in: 1981,
  },
  {
    id: 3,
    name: 'Nirvana',
    albums: 3,
    members: 3,
    formed_in: 1987,
  },
];


function Test2() {
  const containers = ['1', '2', '3', '4', '5', '6'];
  const [parent, setParent] = useState(null);
  const draggableMarkup = (
    <Draggable id="draggable">Drag me</Draggable>
  );

  const [data, setData] = useState([]);
  const [sortType, setSortType] = useState('albums');

  useEffect(() => {
    const sortArray = type => {
      const types = {
        albums: 'albums',
        members: 'members',
        formed: 'formed_in',
      };
      const sortProperty = types[type];
      const sorted = [...bands].sort((a, b) => b[sortProperty] - a[sortProperty]);
      setData(sorted);
    };

    sortArray(sortType);
  }, [sortType]);




  return (
    <dark>
      <DndContext onDragEnd={handleDragEnd}>
        {parent === null ? draggableMarkup : null}

        {containers.map((id) => (
          // We updated the Droppable component so it would accept an `id`
          // prop and pass it to `useDroppable`
          <Droppable key={id} id={id}>
            {parent === id ? draggableMarkup : 'Drop here'}
          </Droppable>
        ))}
      </DndContext>
      <div className="App">
        SORT TESTING
        
        <select onChange={(e) => setSortType(e.target.value)}>
          <option value="albums">Albums</option>
          <option value="members">Members</option>
          <option value="formed">Formed in</option>
        </select>

        {data.map(band => (
          <div key={band.id} style={{ margin: '30px' }}>
            <div>{`Band: ${band.name}`}</div>
            <div>{`Albums: ${band.albums}`}</div>
            <div>{`Members: ${band.members}`}</div>
            <div>{`Year of founding: ${band.formed_in}`}</div>
          </div>
        ))}
      </div>
    </dark>
  );

  function handleDragEnd(event) {
    const { over } = event;

    // If the item is dropped over a container, set it as the parent
    // otherwise reset the parent to `null`
    setParent(over ? over.id : null);
  }
};

export default Test2;