import React, { useEffect, useState } from 'react';

function PageVisitCounter() {
  const [visitCount, setVisitCount] = useState(0);

  useEffect(() => {
    // Retrieve visit count from local storage
    const storedVisitCount = parseInt(localStorage.getItem('visitCount'), 10);
    // Increment visit count and store it in local storage
    const updatedVisitCount = storedVisitCount ? storedVisitCount + 1 : 1;
    localStorage.setItem('visitCount', updatedVisitCount.toString());
    // Update state with the visit count
    setVisitCount(updatedVisitCount);
  }, []); // Runs only once on component mount

  return (
    <div>
      <p> Your Total Visits: {visitCount}</p>
    </div>
  );
}

export default PageVisitCounter;
