import React, { useState, useEffect } from 'react';
import { DndContext, PointerSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { Droppable } from '../components/DND/Droppable.js';
import { Draggable } from '../components/DND/Draggable.js';
import coursesData from '../data/course_system/coursesdata-mini.json';


import '../planner.css';
import course1 from "../courseimages/course1.png"
import course2 from "../courseimages/course2.png"
import course3 from "../courseimages/course3.png"
import logo from "../courseimages/logo.png"

//'../courseimages/'
function Planner() {
  const containers = ['1', '2', '3', '4', '5', '6'];
  const [parent, setParent] = useState({});
  const [data, setData] = useState([]);
  const [sortType, setSortType] = useState('course_name');

  const device_sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor)
  )

  useEffect(() => {
    const sortArray = type => {
      const types = {
        course_name: 'course_name',
        course_number: 'course_number',
        grades_offered: 'grades_offered',
        credits: 'credits',
        prerequisites: 'prerequisites',
        description: 'description',
      };
      const sortProperty = types[type];
      const sorted = Object.values(coursesData).sort((a, b) => {
        if (sortProperty === 'course_number' || sortProperty === 'credits') {
          return parseFloat(a[sortProperty]) - parseFloat(b[sortProperty]);
        }
        if (sortProperty === 'grades_offered') {
          return a[sortProperty].join(', ').localeCompare(b[sortProperty].join(', '));
        }
        return a[sortProperty].localeCompare(b[sortProperty]);
      });
      setData(sorted);
    };

    sortArray(sortType);
  }, [sortType]);

  const handleDragEnd = (event) => {
    const { over, active } = event;
    if (over) {
      setParent(prev => ({
        ...prev,
        [active.id]: over.id
      }));
    }
  };


  return (
    <over>
      {/* 
      <header>
        <div className="header-logo-container">
          <img className="header-logo" src={logo} alt="LOGO" />
          <span className="header-title">Skipper Log</span>
        </div>
        <ul className="header-navs">
          <li className="header-nav">
           
          </li>
          <li className="header-nav">
            
          </li>
        </ul>
      </header>
      */}
      <core>
        <section id="courses">
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={device_sensors} >

          <div className="courses-title-container">

            <h1 className="courses-title"> <img className="header-logo" src={logo} alt="LOGO" /> Skipper Log</h1>

            <p className="course-subtitle">14 results found</p>
          </div>

          <div className="courses-filter-container">
            <div className="courses-filter">
              <label htmlFor="keywords" className="courses-filter-title">Keywords:</label>
              <input type="text" className="courses-keyinput" name="keywords" />
            </div>

            <div className="courses-filter">
              <label htmlFor="grade" className="courses-filter-title">Grade:</label>
              <select name="grade" className="courses-dropdown">
                <option value="9th">9th Grade</option>
                <option value="10th">10th Grade</option>
                <option value="11th">11th Grade</option>
                <option value="12th">12th Grade</option>
              </select>
            </div>

            <div className="courses-filter">
              <label htmlFor="pre-req" className="courses-filter-title">Prerequisite:</label>
              <select name="pre-req" className="courses-dropdown">
                <option value="none">None</option>
                <option value="required">Required</option>
              </select>
            </div>
          </div>

          <div style={{ marginBottom: '20px' }} >
            <select onChange={(e) => setSortType(e.target.value)}>
              <option value="course_name">Course Name</option>
              <option value="course_number">Course Number</option>
              <option value="grades_offered">Grades Offered</option>
              <option value="credits">Credits</option>
              <option value="prerequisites">Prerequisites</option>
              <option value="description">Description</option>
            </select>
          </div>
          
            <div className='courses-course-container'>
              {data.map((course) => (
                <Draggable key={course.course_number} id={course.course_number}>
                  <div className="courses-course">
                    <img className="courses-course-img" src={course1} alt="course img" />
                    <div className="courses-course-title-container">
                      <span className="courses-course-title">{`Course Name: ${course.course_name}`}: {`Course Number: ${course.course_number}`}</span>
                    </div>
                    <div className="courses-course-detail-container">
                      <div className="courses-course-detail">
                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" width="30" height="30"><path d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" /></svg>
                        <p className="courses-course-detail-p">
                          {`Grades Offered: ${course.grades_offered.join(', ')}`}
                        </p>
                      </div>
                      <div className="courses-course-detail">
                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" width="30" height="30"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 192L256.13 320l-47.95-48M191.95 320L144 272M305.71 192l-51.55 59" /></svg>
                        <p className="courses-course-detail-p">
                          {`Credits: ${course.credits}`}
                        </p>
                      </div>
                      <div className="courses-course-detail">
                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" width="30" height="30"><path d="M336 64h32a48 48 0 0148 48v320a48 48 0 01-48 48H144a48 48 0 01-48-48V112a48 48 0 0148-48h32" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" /><rect x="176" y="32" width="160" height="64" rx="26.13" ry="26.13" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" /></svg>
                        <p className="courses-course-detail-p">{`Prerequisites: ${course.prerequisites}`}</p>
                      </div>
                    </div>
                    <div className="courses-course-detail-addtional-container">
                      <span className="courses-course-detail-addtional">
                        Tonka Online
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" width="47" height="47"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 176v160M336 256H176" /></svg>
                    </div>
                  </div>
                </Draggable>
              ))}
            </div>
            </DndContext>
        </section>

        <section id="sidebar">
          <div className="sidebar-title-container">
            <h2 className="sidebar-title">Your Choice for Next Year</h2>
            <p className="sidebar-subtitle">*THIS IS NOT REGISTERING*</p>
          </div>
          <div className="sidebar-course-container">
            <div className="sidebar-course">
              <div>
                <img className="sidebar-course-img" src={course2} alt="" />
              </div>
              <div className="sidebar-course-detail-container">
                <p className="sidebar-course-tag">#6100</p>
                <h3 className="sidebar-course-title">Accounting I</h3>
                <p className="sidebar-course-credit">0.5 Credit</p>
              </div>
              <div className="sidebar-course-icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" width="47" height="47"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M336 256H176" /></svg>
              </div>
            </div>

            <div className="sidebar-course">
              <div>
                <img className="sidebar-course-img" src={course3} alt="" />
              </div>
              <div className="sidebar-course-detail-container">
                <p className="sidebar-course-tag">#6100</p>
                <h3 className="sidebar-course-title">Accounting I</h3>
                <p className="sidebar-course-credit">0.5 Credit</p>
              </div>
              <div className="sidebar-course-icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" width="47" height="47"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M336 256H176" /></svg>
              </div>
            </div>
             <div style={{ display: '', justifyContent: 'space-between', marginBottom: '20px' }}>
            {containers.map((id) => (
              
              <Droppable key={id} id={id} >
                <div>{parent && Object.values(parent).includes(id) ? null : 'Drop here'}</div>
                {data
                  .filter(course => parent[course.course_number] === id)
                  .map(course => (
                    <Draggable key={course.course_number} id={course.course_number}>

                      <div className="sidebar-course-detail-container">
                        <p className="sidebar-course-tag">{`Course Number: ${course.course_number}`}</p>
                        <h3 className="sidebar-course-title">{`Course Name: ${course.course_name}`}</h3>
                        <p className="sidebar-course-credit">0.5 Credit</p>
                      </div>
                    </Draggable>
                  ))}
              </Droppable>
            ))}
          </div>
          </div>
          <div className="sidebar-calc-container">
            <div className="sidebar-calc-title-container">
              <h3 className="sidebar-calc-title">Credit Calculator</h3>
              <div className="sidebar-clac-filter">
                <label htmlFor="gradeCalc" className="sidebar-clac-filter-title">Grade:</label>
                <select name="gradeCalc" className="sidebar-dropdown">
                  <option value="9th">9th Grade</option>
                  <option value="10th">10th Grade</option>
                  <option value="11th">11th Grade</option>
                  <option value="12th">12th Grade</option>
                </select>
              </div>
            </div>
            <div className="sidebar-calc-calc-container">
              <span className="sidebar-calc-calc-title">Credits collected:</span>
              <span className="sidebar-calc-calc-number">2.500</span>
              <span className="sidebar-calc-calc-title">Credits required:</span>
              <span className="sidebar-calc-calc-number">3.600</span>
              <span className="sidebar-calc-calc-title">Differences:</span>
              <span className="sidebar-calc-calc-number">-1.100</span>
            </div>
          </div>
        </section>
      </core>

    </over>
  );
}

export default Planner;
