import { useState, useEffect } from 'react';

function Clock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  const hh = time.getHours();
  const mm = time.getMinutes();
  const ss = time.getSeconds();

  const hRotation = 30 * hh + mm / 2;
  const mRotation = 6 * mm;
  const sRotation = 6 * ss;

  // Accessing elements by IDs from the Dashboard.js component
  const hr = document.getElementById("hour");
  const min = document.getElementById("min");
  const sec = document.getElementById("sec");

  // Applying transformations to the clock hands
  if (hr && min && sec) {
    hr.style.transform = `rotate(${hRotation}deg)`;
    min.style.transform = `rotate(${mRotation}deg)`;
    sec.style.transform = `rotate(${sRotation}deg)`;
  }

  // Return null as Clock component doesn't render any JSX
  return null;
}

export default Clock;
