import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SchoologyData = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace with your OAuth token and API endpoint
        const token = '45967028b4604f989e58f85b9f2bd874066c94f67';
        const apiUrl = 'https://api.schoology.com/v1/users/me';

        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `OAuth ${token}`,
            'Accept': 'application/json'
          }
        });

        setData(response.data);
      } catch (err) {
        setError('Error fetching data from Schoology API');
        console.error(err);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <dark>Error: {error}</dark>;
  }

  if (!data) {
    return <dark>Loading...</dark>;
  }

  return (
    <dark>
      <h2>Schoology User Data</h2>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </dark>
  );
};

export default SchoologyData;