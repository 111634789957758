import React from 'react';
import AdvancedRenderScheduleBlock from './AdvancedRenderScheduleBlock';

function PeriodRenderer({ currPeriod, periodNumber, renderScheduleBlock }) {
  const isCurrentPeriod = parseInt(currPeriod[0]) === periodNumber;

  return (
    <div className={isCurrentPeriod ? "main-right-period-container main-right-period-container-CURRENT" : "main-right-period-container"}>
      <div>
        <div>
            <AdvancedRenderScheduleBlock base_string={renderScheduleBlock(`${periodNumber}`)} section="a" />
            <AdvancedRenderScheduleBlock base_string={renderScheduleBlock(`${periodNumber}`)} section="b" />
        </div>
        <p className="main-right-duration">
          <AdvancedRenderScheduleBlock base_string={renderScheduleBlock(`${periodNumber}`)} section="c" />
        </p>
      </div>
    </div>
  );
}

export default PeriodRenderer;
