/*

import React, { useState, useEffect } from 'react';
*/
import { Link } from 'react-router-dom';

function Home() {

  const date = new Date();

  const yearNum = date.getFullYear();



  //const secondNum = date.getSeconds()


  var finalYearString = "";




  //handle year formatting
  finalYearString = String(yearNum);

  // look into to https://drawsvg.org/
  // for the custom svg icons for the home page

  //https://www.svgrepo.com/
  // just dowload and copy html from f12


  return (

    <shell>

      <dark>
        <div class="home-div">


          <div class="Schedule">
            <Link to="/"><div class="footer-home-container">
              <div class="footer-home">
                <p class="footer-home-period"> <svg xmlns="http://www.w3.org/2000/svg" width="70%" height="70%" viewBox="0 0 24 24" fill="none">
                  <path d="M12 7V12L14.5 10.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg></p>            <p class="footer-home-detail">Schedule </p>

              </div>
            </div></Link>
          </div>

          <div class="Planner">
            <Link to="/planner"><div class="footer-home-container">
              <div class="footer-home">
                <p class="footer-home-period"><svg xmlns="http://www.w3.org/2000/svg" width="70%" height="70%" viewBox="0 0 24 24" fill="none" transform="rotate(180)">

                  <g id="SVGRepo_bgCarrier" stroke-width="0" />

                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

                  <g id="SVGRepo_iconCarrier"> <path d="M9 12H21M9 4L9 20M6.2 20H17.8C18.9201 20 19.4802 20 19.908 19.782C20.2843 19.5903 20.5903 19.2843 20.782 18.908C21 18.4802 21 17.9201 21 16.8V7.2C21 6.0799 21 5.51984 20.782 5.09202C20.5903 4.71569 20.2843 4.40973 19.908 4.21799C19.4802 4 18.9201 4 17.8 4H6.2C5.0799 4 4.51984 4 4.09202 4.21799C3.71569 4.40973 3.40973 4.71569 3.21799 5.09202C3 5.51984 3 6.07989 3 7.2V16.8C3 17.9201 3 18.4802 3.21799 18.908C3.40973 19.2843 3.71569 19.5903 4.09202 19.782C4.51984 20 5.07989 20 6.2 20Z" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /> </g>

                </svg></p>
                <p class="footer-home-detail">Planner</p>

              </div>
            </div></Link>
          </div>

          <div class="info">
            
            <h1>hello, home page</h1>
            <h1>{finalYearString}</h1>
            <h2>
            <Link to="/test" >TESTING</Link>
            _____
            <Link to="/test2" >TESTING2</Link>
            _____
            <Link to="/sign" >SIGN</Link>
            </h2>
          </div>
        </div>
      </dark>
    </shell >
  );
}



export default Home;



