import React, { useEffect, useState } from "react";

function Modal() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setShowModal(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
    localStorage.setItem("isModal", false);
  };

  if (!showModal) return null;

  if (showModal) {
    return (
      <div className="modal-overlay" onClick={handleClose}>
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <span className="modal-close" onClick={handleClose}>
            x
          </span>
          <h2>Add Student Assist to your home screen!</h2>
          <p>Quickly access this app by adding it to your home screen.</p>
          <div className="modal-divider">
            <span>1. Select your lunch period below</span>
            <span>2. Tap the "Share" button</span>
            <span>3. Tap "Add to Home Screen"</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
