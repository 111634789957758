// TESTING VERSION

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Dashboard from "./pages/Schedule"
import Home from './pages/Home';
import Planner from './pages/Planner';
import Test from './pages/Test';
import Test2 from './pages/Test2'
import Dashboardsign from './pages/Schedule-Sign.js';
import SchoologyData from './pages/Test3'

function App() {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-B8Q4LZMHCR');
    
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-B8Q4LZMHCR`;
    script.async = true;
    document.body.appendChild(script);
  }, []);


  return (
    <Router>
      <Routes>
          <Route exact path="/"  element={<Dashboard/>} />
          <Route exact path="/home"  element={<Home/>} />
          <Route exact path="/planner" element={<Planner/>}/>
          <Route exact path='/test' element={<Test/>}/>
          <Route exact path='/test2' element={<Test2/>}/>
          <Route exact path='/test3' element={<SchoologyData/>}/>
          <Route exact path='/sign' element={<Dashboardsign/>}/>


      </Routes>
    </Router>
  );
}
  
export default App;

// PROD VERSION

/*


import React, { useEffect } from 'react';

import { HashRouter as Router, Routes, Route } from "react-router-dom"
import Dashboard from "./pages/Schedule"
import Home from './pages/Home';
import Planner from './pages/Planner';

function App() {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-B8Q4LZMHCR');
    
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-B8Q4LZMHCR`;
    script.async = true;
    document.body.appendChild(script);
  }, []);


  return (
    <Router>
      <Routes>
          <Route exact path="/schedule"  element={<Dashboard/>} />
          <Route exact path="/"  element={<Home/>} />
          <Route exact path="/planner" element={<Planner/>}/>
      </Routes>
    </Router>
  );
}
  
export default App;

*/