// CheckedItemsRenderer.js

import React from 'react';
import weekData from "../data/schedule_system/output.json";
import templateData from "../data/schedule_system/scheduleTemplates.json";


//convert time formats
const convertTime = (_hr, _min, _total_min, _timeStr) => {
  var hr = _hr;
  var min = _min;
  var totalMin = _total_min;
  var timeStr = _timeStr;
  var minStr;
  if (!(hr === 0 && min === 0)) {
    //convert hr, min to total minutes
    totalMin = (hr * 60) + min;
    //convert hr, min to time string
    minStr = String(min)
    if (min < 10) {
      minStr = "0" + minStr;
    }
    timeStr = String(hr) + ":" + minStr;
  }
  else if (!(totalMin === 0)) {
    //convert total Min to hr, min
    hr = Math.floor(totalMin / 60);
    min = totalMin % 60;
    //convert hr, min to time string 
    minStr = String(min)
    if (min < 10) {
      minStr = "0" + minStr;
    }
    timeStr = String(hr) + ":" + minStr;
  }
  else if (!(timeStr === "")) {
    //convert timeStr to hr, min
    var strCopy = timeStr.split(':')
    hr = parseInt(strCopy[0]);
    min = parseInt(strCopy[1]);
    //convert hr, min to totalMin
    totalMin = (hr * 60) + min;
  }
  return [[hr, min], totalMin, timeStr];
}
//displayTimeStr
const DTS = (timeStr) => {
  if (String(timeStr).length < 2) {
    return "0";
  }
  var strCopy = timeStr.split(':');
  var hr = parseInt(strCopy[0])
  if (hr > 12) {
    hr -= 12;
  }
  return (String(hr) + ':' + strCopy[1]);
}

//simplified checkbox render
const renderSimplified = (indx) => {
  var index = parseInt(indx[6]);


  const date = new Date();


  const yearNum = date.getFullYear();
  const monthNum = date.getMonth() + 1; //months start at 0
  const dateNum = date.getDate();


  // const secondNum = date.getSeconds()


  var finalYearString = "";
  var finalMonthString = "";
  var finalDateString = "";


  //handle year formatting
  finalYearString = String(yearNum)


  //handle month formatting
  if (monthNum < 10) {
    finalMonthString = "0" + String(monthNum);
  } else finalMonthString = String(monthNum);


  //handle date formatting
  if (dateNum < 10) {
    finalDateString = "0" + String(dateNum);
  } else finalDateString = String(dateNum);




  //final date
  const formattedDate = finalYearString + "-" + finalMonthString + "-" + finalDateString;


  //get date data
  // const isDayOff = String(weekData[formattedDate]["isDayOff"]);
  const scheduleTemplate = String(weekData[formattedDate]["schedule"]);


  const x = scheduleTemplate[0];
  const y = scheduleTemplate[1];


  var totalData = templateData[x][y];

  //example on how to reference different periods
  const periodData = totalData["periods"];

  //const per1 = periodData["1"];
  //const per2 = periodData["2"];
  //const per3 = periodData["3"];


  //lunch a
  const per4a = periodData["4a"][0];
  const lunch4a = periodData["4a"][1];


  //lunch b (split lunch)
  const per4b_part1 = periodData["4b"][0];
  const lunch4b = periodData["4b"][1];
  const per4b_part2 = periodData["4b"][2];


  //lunch c (last lunch)
  const per4c = periodData["4c"][1];
  const lunch4c = periodData["4c"][0];


  //const per5 = periodData["5"];
  //const per6 = periodData["6"];


  //anchor time
  //const anchorTime = periodData["AnchorTime"];
  //const anchorIndex = totalData["AnchorIndex"];
  //const periodNames = Object.keys(periodData);

  switch (index) {

    case 4:
      return (
        <div>
          <div className='main-right-duration'>
            <div className='main-right-period-time-container'>Period 4a: {per4a[0]} {"-"} {per4a[1]}</div>
            <div className='main-right-period-time-container'>Lunch: {lunch4a[0]} {"-"} {lunch4a[1]}</div>
          </div>
          <div className='main-right-duration'>
            <div className='main-right-period-time-container'>Period 4b: {per4b_part1[0]} {"-"} {per4b_part1[1]}</div>
            <div className='main-right-period-time-container'>Lunch: {lunch4b[0]} {"-"} {lunch4b[1]}</div>
            <div className='main-right-period-time-container'>Period 4b: {per4b_part2[0]} {"-"} {per4b_part2[1]}</div>
          </div>
          <div className='main-right-duration'>
            <div className='main-right-period-time-container'>Lunch: {lunch4c[0]} {"-"} {lunch4c[1]}</div>
            <div className='main-right-period-time-container'>Period 4c: {per4c[0]} {"-"} {per4c[1]}</div>
          </div>
        </div>
      );
    //lunch A, 3rd lunch
    case 3:
      return (
        <div className='main-right-duration'>
          <div className='main-right-period-time-container'>Period 4a: {per4a[0]} {"-"} {per4a[1]}</div>
          <div className='main-right-period-time-container'>Lunch: {lunch4a[0]} {"-"} {lunch4a[1]}</div>
          {/* <div className='main-right-period-title'>Period 4a:</div>
          <div className='main-right-period-time-container'>{per4a[0]} {"-"} {per4a[1]}</div>
          <div className='main-right-period-title'>Lunch:</div>
          <div className='main-right-period-time-container'>{lunch4a[0]} {"-"} {lunch4a[1]}</div> */}
        </div>
      );
    //Lunch B, split lunch
    case 2:
      return (
        <div className='main-right-duration'>
          <div className='main-right-period-time-container'>Period 4b: {per4b_part1[0]} {"-"} {per4b_part1[1]}</div>
          <div className='main-right-period-time-container'>Lunch: {lunch4b[0]} {"-"} {lunch4b[1]}</div>
          <div className='main-right-period-time-container'>Period 4b: {per4b_part2[0]} {"-"} {per4b_part2[1]}</div>
        </div>
      );
    //lunch C, 1st lunch
    case 1:
      return (
        <div className='main-right-duration'>
          <div className='main-right-period-time-container'>Lunch: {lunch4c[0]} {"-"} {lunch4c[1]}</div>
          <div className='main-right-period-time-container'>Period 4c: {per4c[0]} {"-"} {per4c[1]}</div>
        </div>
      );
    default:
      return <div>Error</div>
  }
}
//render the expanded checkbox
const renderExpanded = (indx, currTimeList) => {
  var index = parseInt(indx[6]);

  //GET DATE
  const date = new Date();

  const yearNum = date.getFullYear();
  const monthNum = date.getMonth() + 1; //months start at 0
  const dateNum = date.getDate();


  //const secondNum = date.getSeconds()


  var finalYearString = "";
  var finalMonthString = "";
  var finalDateString = "";


  //handle year formatting
  finalYearString = String(yearNum)


  //handle month formatting
  if (monthNum < 10) {
    finalMonthString = "0" + String(monthNum);
  } else finalMonthString = String(monthNum);


  //handle date formatting
  if (dateNum < 10) {
    finalDateString = "0" + String(dateNum);
  } else finalDateString = String(dateNum);




  //final date
  const formattedDate = finalYearString + "-" + finalMonthString + "-" + finalDateString;


  //get date data
  //const isDayOff = String(weekData[formattedDate]["isDayOff"]);
  const scheduleTemplate = String(weekData[formattedDate]["schedule"]);


  const x = scheduleTemplate[0];
  const y = scheduleTemplate[1];


  var totalData = templateData[x][y];

  //example on how to reference different periods
  const periodData = totalData["periods"];

  //const per5 = periodData["5"];


  //period 4 lunches time list
  const per4a = periodData["4a"];
  const per4b = periodData["4b"];
  const per4c = periodData["4c"];

  //find the current time
  const hours = currTimeList[0];
  const mins = currTimeList[1];



  //find the overlapping hour
  const currentTimeInMinutes = convertTime(hours, mins, 0, "")[1];

  const findOverlappingHour = (lunchPeriod) => {
    //for each [start, stop], 
    for (let i = 0; i < lunchPeriod.length; i++) {
      const startStr = lunchPeriod[i][0];
      const stopStr = lunchPeriod[i][1];

      const startTime = convertTime(0, 0, 0, startStr)[1];
      const stopTime = convertTime(0, 0, 0, stopStr)[1];

      //if this is the contained hour
      if (startTime - 5 <= currentTimeInMinutes && currentTimeInMinutes <= stopTime) {

        //if its actually, currently this hour and not passing time
        if (startTime <= currentTimeInMinutes && currentTimeInMinutes <= stopTime) {
          //if there is another hour after this within the lunch period
          if (i + 1 <= lunchPeriod.length - 1) {
            return ([i, true, false]);
          }
          else {
            return ([i, false, false]);
          }
        }
        //otherwise if its passing time, return that it is
        else {
          if (i + 1 <= lunchPeriod.length - 1) {
            return ([i, true, true]);
          }
          else {
            return ([i, false, true]);
          }
        }

      }
    }
  }

  //subtractTimeStrings
  const currTimeString = convertTime(hours, mins, 0, "")[2];
  const subtractTimeString = (timeStr1, timeStr2) => {
    var t1 = convertTime(0, 0, 0, timeStr1)[1];
    var t2 = convertTime(0, 0, 0, timeStr2)[1];

    const finalTime = Math.abs(t1 - t2);

    return (convertTime(0, 0, finalTime, "")[1]);
  }

  //lunch A (last lunch), index === 3
  if (index === 3) {
    //calculations
    const out = findOverlappingHour(per4a);
    const currHourIndexWithinTheLunch = out[0];
    const isAnotherLunchHour = out[1];

    //rendering if passing time
    if (out[2]) {
      return (
        <>
          <div className="text">passing time</div>
        </>
      )
    }
    //this code is not needed anymore, but its good to have one copy laying around so that its really easy to reimplement
    // var nextHourStartString = "";
    // if (isAnotherLunchHour) {
    //   nextHourStartString = per4a[currHourIndexWithinTheLunch + 1][0];
    // }
    // else {
    //   nextHourStartString = per5[0];
    // }

    var nextHourStartString = "";
    if (isAnotherLunchHour) {
      nextHourStartString = per4a[currHourIndexWithinTheLunch][1];
    }
    else {
      nextHourStartString = per4a[1][1];
    }

    const timeTillNextHour = subtractTimeString(currTimeString, nextHourStartString);

    //const currHourStart = per4a[currHourIndexWithinTheLunch][0];
    //const currHourStop = per4a[currHourIndexWithinTheLunch][1];


    //render
    return (
      <>
        <div className="text">ends in:</div>
        <div>{timeTillNextHour} minutes</div>
        <div className="text">at {DTS(nextHourStartString)}</div>
      </>
    );
  }
  //lunch B (split lunch)
  else if (index === 2) {
    //calculations
    const out = findOverlappingHour(per4b);
    const currHourIndexWithinTheLunch = out[0];
    const isAnotherLunchHour = out[1];

    //rendering if passing time
    if (out[2]) {
      return (
        <>
          <div className="text">passing time</div>
        </>
      )
    }


    nextHourStartString = "";
    if (isAnotherLunchHour) {
      nextHourStartString = per4b[currHourIndexWithinTheLunch][1];
    }
    else {
      nextHourStartString = per4b[currHourIndexWithinTheLunch][1];
    }
    const timeTillNextHour = subtractTimeString(currTimeString, nextHourStartString);

    //const currHourStart = per4b[currHourIndexWithinTheLunch][0];
    //const currHourStop = per4b[currHourIndexWithinTheLunch][1];


    //render
    return (
      <>
        <div className="text">ends in:</div>
        <div>{timeTillNextHour} minutes</div>
        <div className="text">at {DTS(nextHourStartString)}</div>
      </>
    );
  }
  //lunch C, aka first lunch
  else if (index === 1) {
    const out = findOverlappingHour(per4c);
    const currHourIndexWithinTheLunch = out[0];
    const isAnotherLunchHour = out[1];


    //rendering if passing time
    if (out[2]) {
      return (
        <>
          <div className="text">passing time</div>
        </>
      )
    }

    nextHourStartString = "";
    if (isAnotherLunchHour) {
      nextHourStartString = per4c[currHourIndexWithinTheLunch][1];
    }
    else {
      nextHourStartString = per4c[1][1];
    }
    const timeTillNextHour = subtractTimeString(currTimeString, nextHourStartString);

    //const currHourStart = per4c[currHourIndexWithinTheLunch][0];
    //const currHourStop = per4c[currHourIndexWithinTheLunch][1];


    //render
    return (
      <>
        <div className="text">ends in:</div>
        <div>{timeTillNextHour} minutes</div>
        <div className="text">at {DTS(nextHourStartString)}</div>
      </>
    );
  }
}





const CheckedItemsRenderer = ({ checkboxStates, showAdditionalContent, timeslot, currTimeList, showall }) => {
  //console.log(checkboxStates);
  const renderCheckedItems = () => {
    const checkedItems = checkboxStates

      .map((isChecked, index) => isChecked ? `Lunch ${index + 1}` : null)
      .filter(Boolean);


    if (checkedItems.length === 0) {
      return (
        <div>
        {showall ? (
          renderSimplified(["", "", "", "", "", "", "4"])
        ) : (
          <div>Select a Lunch Period to View Times</div>
        )}
      </div>
      );
      //{renderSimplified(["", "", "", "", "", "", "4"])} 
    }



    
    if (checkedItems.length > 1) {
      return (
        <div className="checked-item-box">
          <div>
            only select one lunch
          </div>
          <div>
            at a time
          </div>
        </div>
      );
    }

    //conditionally rendering lunches
    //const selectedLunchNum = checkedItems[0][6];


    //var lunchTitle;
    //if (selectedLunchNum === '1') {
    //this is lunch C, also known as first lunch 
    //  lunchTitle = "1st Lunch:";
    //} else if (selectedLunchNum === '2') {
    //this is lunch B,  also known as split lunch
    //  lunchTitle = "2nd Lunch:";
    //} else if (selectedLunchNum === '3') {
    //this is lunch C, also known as 3rd or last lunch
    //  lunchTitle = "3rd Lunch:";
    //}

    return checkedItems.map((item, index) => (
      <>
        {showAdditionalContent && renderExpanded(item, currTimeList)}
        {timeslot && renderSimplified(item)}
      </>
    ));
  };

  return (
    <>
      {renderCheckedItems()}
    </>
  );
};

export default CheckedItemsRenderer;
