import React, { useEffect } from 'react';
import "../style.css"


function sidebarRender(index) {
  switch (index) {
    case 0: {
      return (
        <>
          <div className='footer-lunch-detail'>Lunch</div>
          <div className='footer-lunch-detail'>Period 4c</div>
        </>
      );
    }
    case 1: {
      return (
        <>
          <div className='footer-lunch-detail'>Period 4b</div>
          <div className='footer-lunch-detail'>Lunch</div>
          <div className='footer-lunch-detail'>Period 4b</div>
        </>
      );
    }
    case 2: {
      return (
        <>
          <div className='footer-lunch-detail'>Period 4a</div>
          <div className='footer-lunch-detail'>Lunch</div>
        </>
      );
    }
    default: {
      // Return null or some default content
      return null;
    }
  }
}

const CheckboxLogger = ({ checkboxStates, onCheckboxChange }) => {
  const logCheckboxStates = () => {
    //console.log('Checkbox States:', checkboxStates);
  };

  useEffect(() => {
    // Initial log
    logCheckboxStates();

    // Set interval to log every 10 seconds
    const intervalId = setInterval(logCheckboxStates, 10000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [checkboxStates]);

  const convertIndexToLunchTitle = (_index) => {
    var outTxt = "";

    //first lunch
    if (_index === 1) {
      outTxt = "1st Lunch";
    } else if (_index === 2) {
      outTxt = "2nd Lunch";
    } else {
      outTxt = "3rd Lunch";
    }

    return (
      <>
        {outTxt}
      </>
    );
  }

  return (
    <div className="footer-lunch-container">
      {checkboxStates.map((isChecked, index) => (
        <div key={index} className="vertical-div">
          <span
            className={`footer-lunch-period ${isChecked ? 'footer-lunch-selected' : ''}`}
          >
            <div className="footer-lunch">

              <p className="footer-lunch-period">{convertIndexToLunchTitle(index + 1)}</p>
              <div className="footer-lunch-detail-container">
                {sidebarRender(index)}
              </div>
              <input
                type="checkbox"
                id={`checkbox-${index}`}
                checked={isChecked}
                onChange={() => onCheckboxChange(index)}
              />
            </div>
          </span>
        </div>
      ))}
    </div>
  );
};

export default CheckboxLogger;
