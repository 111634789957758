import React from 'react';
import "../style.css"

const calculateDuration = (startTime, endTime) => {
  // Ensure startTime and endTime are strings
  if (typeof startTime !== 'string' || typeof endTime !== 'string') {
    return '';
  }

  // Parse hours and minutes from startTime and endTime
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  // Calculate duration
  const startTotalMinutes = startHour * 60 + startMinute;
  const endTotalMinutes = endHour * 60 + endMinute;
  let durationMinutes = endTotalMinutes - startTotalMinutes;

  // Handle cases where endTime is before startTime (e.g., crossing midnight)
  if (durationMinutes < 0) {
    durationMinutes += 24 * 60; // Add 24 hours in minutes
  }

  // Handle cases the startTime and endTime span over 12pm -> 1pm
  if (durationMinutes > (12 * 60)) {
    durationMinutes -= 12 * 60
  }

  // Convert duration back to hours and minutes
  const durationHours = Math.floor(durationMinutes / 60);
  const durationRemainingMinutes = durationMinutes % 60;
  const durationHoursString = durationHours > 0 ? `${durationHours}${durationHours > 1 ? "hrs" : "hr"} ` : '';

  return `${durationHoursString}${durationRemainingMinutes}mins`;
};

function AdvancedRenderScheduleBlock({ base_string, section }) {


  

  let content;
  const childrenArray = React.Children.toArray(base_string.props.children);
  
  switch (section) {
    case 'a':
      
      
      
        content= 
        <div class="main-right-period-title">
          
        {childrenArray[0]} {childrenArray[1]}{childrenArray[2]}
        </div>
        // Do something if the string does not contain "AnchorTime"
        
        

      break;
    case 'b':
      content = 
      <div class="main-right-period-time-container">
      {childrenArray[3]}{childrenArray[4]} {childrenArray[5]}
      </div>;
      break;
    case 'c':

      
      let time1= childrenArray[3];
      let time2= childrenArray[5];
      content =
      <div class="main-right-period-time-container">
        
        {calculateDuration(time1,time2)}
      
      </div>;
      break;
    default:
      content = {base_string};
  }

  return (
    <div>
      {content}
    </div>
  );
}

export default AdvancedRenderScheduleBlock;
